import React from 'react';
import './App.css';
import AboutMe from './components/AboutMe/AboutMe'
import InnyBirthday from './components/InnyBirthday/InnyBirthday'
import Music from './components/Music/Music'
import Projects from './components/Projects/Projects'
import ReactGA from 'react-ga';

import { BrowserRouter as Router, Route } from 'react-router-dom';

function initializeReactGA() {
    ReactGA.initialize('UA-140808766-2');
    ReactGA.pageview('/home');
}

function App() {
    initializeReactGA();
    return (
        <>
            <div className="App">
                <Router>
                    <Route path="/" exact component={AboutMe} />
                    <Route path="/music" component={Music} />
                    <Route path="/happybirthdayinny" component={InnyBirthday} />
                    <Route path="/projects" render={(props) => <Projects {...props}/>}  />
                </Router>
            </div>
        </>
    );
}

export default App;

import React from 'react'
import { Link } from 'react-router-dom';


import './AboutMe.css'

const AboutMe = () => {
    return (
        <div class='wrapper'>
            <div class='landing' id='landing'>
                <h1>hello, i'm nathan</h1>
                <p>
                    I love exploring and fully understanding new concepts ranging from academia to worldly processes. Huge fan of Go, basketball, Kendrick, distributed systems and anime!
                </p>

                <h1>experiences</h1>
                <div>
                    <p>Citadel [Summer 2022]</p>
                    <p>
                    <text>Designed a distributed load testing framework; leveraged the framework to compare the performance between cloud databases to establish a standard for the cloud.</text>
                    </p>
                </div>
                <div>
                    <p>Meta [Winter 2022]</p>
                    <p>
                    <text>Worked on Chronos, Meta's distributed job scheduler backing Instagram, Facebook, and ML teams. Refactored the host-matching algorithm to reduce its time complexity.</text>
                    </p>
                </div>
                <div>
                    <p>Citadel [Summer 2021]</p>
                    <p>
                    <text>Built a distributed application to track/log/aggregate all file operations within the firm for compliance purposes.</text>
                    </p>
                </div>
                <div>
                    <p>Autodesk [Fall 2020]</p>
                    <p>
                    <text>Executed DevOps responsibilities including database migrations, Docker administration, and building an admin console.</text>
                    </p>
                </div>
                
                <h1>say hi</h1>
                <p>

                <ul>
                    <li><a target="_blank" className='bold' href='mailto:ntau@uwaterloo.ca'>Email</a></li>
                    <li><a target="_blank" className='bold' href='https://www.linkedin.com/in/nathantau/'>LinkedIn</a></li>
                    <li><a target="_blank" className='bold' href='https://github.com/nathantau'>GitHub</a></li>
                    {/* <li><Link className='bold' to='/music'>My really old and out-of-date music collection</Link></li> */}
                </ul>
                </p>
                <p>
                    If you're reading this, have a stellar day 🌠
                </p>
            </div>
        </div>
    )
}

export default AboutMe;

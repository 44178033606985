import React from 'react'
import './Projects.css'

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import ParticleCatch from './ProjectPages/ParticleCatch/ParticleCatch'

const Project = ({ name, short, long, links, url }) => {
    // Props should contain the project name, (short and long) description, image path, and link(s)
    // Should limit short to 1 sentence.
    return (
        <div className='project'>
            <h2>{name}</h2>
            <p>{short}</p>
            {
                url ?
                    <Link to={`${url}/${name.replace(/\s/g, '')}`}>
                        <img src={require(`../assets/projects/${name}.jpg`)}></img>
                    </Link> :
                    <img src={require(`../assets/projects/${name}.jpg`)}></img>
            }
            <p>{long}</p>
            {
                links.length > 0 ?
                    <h5>Liked this project? Visit {
                        links.map(link => <a key={link} href={link}>{link}.</a>)
                    }</h5> : null
            }
        </div>
    )
}

const Projects = (props) => {
    return (
        <Router>
            <Route path={`${props.match.path}/ParticleCatch`} component={ParticleCatch} ></Route>
            <Route exact path={`${props.match.path}`} component={() => {
                return (
                    <div id='projects'>
                        <h1>My spiritual lyrical mythical Projects. 🦄</h1>
                        <Project
                            name='HealthyU' links={['https://healthyubackend.nathanau.ca']}
                            short='Become a healthier you!'
                            long='HealthyU is a full-stack application dedicated to helping users reach their fitness goals. Simply login, upload pictures of your meal (or its ingredients), and HealthyU will compute and store the total number of calories in that meal. Users can then track their caloric intake over time, displayed on a dynamic graph. Accounts are handled with JSON Web Tokens (JWT) and user credentials are hashed and salted before being stored in a database. The frontend is built with React while the backend is built with Node, Express, and MongoDB. This project was deployed using Docker and uses NGINX as a reverse-proxy.'
                        ></Project>
                        <Project
                            name='Boardless' links={['https://boardless.nathanau.ca']}
                            short='No Whiteboard? No Problem!'
                            long='Due to COVID-19, traditional classroom teaching is no longer an option. Boardless seeks to bridge the gap in the education space by providing whiteboards in virtual classrooms where teachers and students can collaborate and interact in real time. Due to integration with Socket.io, Boardless provides low-latency synchronization between users. Boardless also supports video calling with WebRTC to provide a familiar experience in these challenging times. This project was built using the MERN stack (MongoDB, Express, React, Node) and deployed with Docker Compose.'
                        ></Project>
                        <Project
                            name='BigBrain' links={['https://github.com/nathantau/BigBrain']}
                            short='Brain Tumor Detection with a CNN.'
                            long='After solidifying the theory behind Image Recognition with Andrew Ng’s Machine Learning Course from Stanford University, I decided to build an ML application that could benefit society; BigBrain is a program developed with Python and Pytorch that uses a Convolutional Neural Network (CNN) to detect the presence of tumors on given MRIs of brains. Although this project was initially launched on a Flask/Kubernetes Cluster on Google Cloud Platform, unfortunately, due to financial reasons, it is no longer deployed.'
                        ></Project>
                        <Project
                            name='Smile' links={['https://github.com/nathantau/Smile']}
                            short='An exploration into Localization with ML.'
                            long='Object detection is another area of Machine Learning that greatly interests me - one of the key aspects is localization, which is the process of creating bounding boxes around certain features. To apply my knowledge of this concept, I decided to build facial recognition software to determine key features on a person’s face (eyes, nose, and upper lip). I used a dataset from Kaggle to train my Keras CNN, and after 60 epochs of training, I was able to achieve a relatively low loss.'
                        ></Project>
                        <Project
                            url={props.match.url}
                            name='ParticleCatch' links={['https://play.google.com/store/apps/details?id=ca.nathanau.canvasproject2']}
                            short='Android arcade game supported by custom physics engine.'
                            long='ParticleCatch is an arcade game with custom rendering and physics logic built from the ground up. The user guides a block to catch randomly spawned particles that may collide with the sides of the screen, similar to collisions in first-order Newtonian physics. It is designed for Android devices, and is downloadable from the Google Play Store.'
                        ></Project>
                        <Project
                            name='Corona' links={['https://corona.nathanau.ca']}
                            short='A lightweight heatmap for COVID19.'
                            long='With numerous Coronavirus trackers around, how do you choose the right one to use? Is it simplicity? Attention to detail? UI/UX? Well, this React tracker incorporates all of these components to provide a truly unique and quality experience for its users.'
                        ></Project>
                        <Project
                            name='Youuuurl' links={['https://github.com/nathantau/YouuuuRL']}
                            short='A standard URL-Shortener API leveraging Compose.'
                            long='What started off as a discovery of how URL-Shorteners work, ended with me doing a speed build of a fully functional (with unit tests included) backend Python/Flask API to shorten and expand given URL strings (with Base 62 Conversion). User authentication leverages JWT and all persistent data is stored in a PostgreSQL database.'
                        ></Project>
                        <Project
                            name='Physics Learner' links={['https://github.com/nathantau/PhysicsLearner']}
                            short='Making physics easier for everyone!'
                            long='As a Senior, I realized that many students struggled with the physics courses offered at my high school. My friends and I decided to build a physics environment with Java where students can easily run simulations of Kinematics, Dynamics, Electricity, and Light. Explanations accompany such simulations and concisely but accurately describe what is happening.'
                        ></Project>
                        <Project
                            name='Rick and Morty Chess' links={['https://github.com/nathantau/RM_Chess']}
                            short='Wubalubadubdub!'
                            long='R&M Chess is a Chess game that closely resembles the classic Windows Chess Game (that no longer ships with Windows :(). It was built with C++, uses advanced concepts such as Polymorphism, Inheritance, and Pointers, and leverages OOP.'
                        ></Project>
                        <h2>Glad you made it this far! My Github can be found <a href='https://github.com/nathantau'>here</a>, which showcases my source code and more projects.</h2>
                        <h4>Fun fact, this website was speed-built in a day with React.js and lots of 🍵 (tea). My old website can be found at <a href='https://old.nathanau.ca/'>old.nathanau.ca</a></h4>
                    </div>
                )
            }
            }></Route>

        </Router>
    )
}

export default Projects;
import React, { useState, useEffect } from 'react'
import { csvParse } from 'd3'

import { TextField } from '@material-ui/core';


import './Music.css'

const Music = () => {
    const [loading, setLoading] = useState(true);
    const [songs, setSongs] = useState([]);
    // Filters to search for songs
    const [filter, setFilter] = useState('');
    const fetchSongs = async filename => {
        return await fetch(filename)
            .then(response => response.text())
            .then(csv => {
                const parsed = csvParse(csv);
                setSongs(parsed);
                setLoading(false);
            })
    }
    useEffect(() => {
        fetchSongs('/songs.csv');
    }, [])
    return (
        <div id='music'>
            <h1>Old music collection.</h1>
            {/* <TextField color='secondary' label='Artist/Album/Genre' onChange={e => setFilter(e.target.value)}></TextField> */}
            <ol>
                {
                    loading ? 'Loading...' :
                        songs
                            // .filter(({ Artist, Album, Genre }) => {
                            //     let lowercaseFilter = filter.toLowerCase();
                            //     return Artist.toLowerCase().includes(lowercaseFilter) ||
                            //         Album.toLowerCase().includes(lowercaseFilter) ||
                            //         Genre.toLowerCase().includes(lowercaseFilter);
                            // })
                            .map(song => {
                                return (
                                    <li key={song.Album + song.Artist + song.Song + song.Genre}><span className='song'>{song.Song}</span> by <span className='artist'>{song.Artist}</span> from <span className='album'>{song.Album}</span></li>
                                )
                            })
                }
            </ol>
        </div>
    )
}

export default Music;
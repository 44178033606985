import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom';


import './InnyBirthday.css'

const InnyBirthday = () => {
    return (
        <div class='wrapper'>
            <div class='landing' id='landing'>
                <h1>Happy 23rd Birthday Babe!</h1>
                <p>
                    Thanks for giving me the chance to be your boyfriend, I am learning so much 🤓 ~ here are a few of our best memories we've had together :D
                </p>
                {
                    [
                    {
                        "title": "Nov. 4 - Clubbing night",
                        "description": "Thanks for making the first move Inny, much appreciated!",
                        "path": require("../assets/memories/1.jpg"),
                    },
                    {
                        "title": "Nov. 11/18 - First and second weekends together",
                        "description": "Thanks for teaching me how to **** and eating Jolibee leftovers with me, much appreciated Inny!",
                        "path": require("../assets/memories/2.jpg"),
                    },
                    {
                        "title": "Nov. 26 - We're married??? ft. HK Bistro Cafe",
                        "description": "Thanks for saying 'yes' Inny, how thoughtful of you! Things I learned on this episode: Inny is an okay skater and can’t tank large amounts of Korean food well :”).",
                        "path": require("../assets/memories/3.jpg"),
                    },
                    {
                        "title": "Dec. 3 - Waterloo is a shit city",
                        "description": "Hope all is well Inny, including the Lazeez we shared. Note to self: don’t make super spicy foods for babe.",
                        "path": require("../assets/memories/4.jpg"),
                    },
                    {
                        "title": "Dec. 12 - The steamy Facetimes during exam szn",
                        "description": "This was a super hard period of time and it became harder and harder for small talk because all we wanted to do was ****. But super happy that now we’re very comfortable with each other and okay with silence as long as we’re in each others’ presence.",
                        "path": require("../assets/memories/5.jpg"),
                    },
                    {
                        "title": "Dec. 19 - Reunited at last",
                        "description": "From our walk of shame to get rubber ducks to eating dinner with the RIZZLER to trying out a bunch of restaurant spots, this week was definitely the highlight of the year (RIP Groveners).",
                        "path": require("../assets/memories/6.jpg"),
                    },
                    {
                        "title": "Dec. 21 - Christmas market shenanigans",
                        "description": "Our first successfully failed mission: Step 1 - Uber to the Christmas Market. Step 2 - Get told to leave because it’s full. Step 3 - Try 7 different paths through the neighbouring condos and parking lots to sneak into said market. Step 4 - Accidentally end up at the entrance and get free entry. Step 5 - Cute photos with the cutest girl.",
                        "path": require("../assets/memories/7.jpg"),
                    },
                    {
                        "title": "Dec. 30 - Singing anime songs and water bottle delivery service",
                        "description": "I was dying of thirst so TYSM. But also dinner and karaoke with Polar Bear was super fun. I fell more in love especially after hearing your singing voice FRFR.",
                        "path": require("../assets/memories/8.jpg"),
                    },
                    {
                        "title": "Dec. 31 - New Year's Eve moving",
                        "description": "I don't have photos of this weekend :(. Strong and independent woman vibes :0.",
                        "path": "",
                    },
                    {
                        "title": "Jan. 21 - RIGATONI",
                        "description": "Our second successfully failed mission. Step 1 - Go to Sugo. Step 2 - Get put on wait list for 1 hour. Step 3 - Try shawarma pizza (holy shit that was so good). Step 4 - Try a Korean sushi place. Step 5 - Thrift and meme. Step 6 - Eat rigatoni at Sugo (it was better than the spaghetti both inside and outside the restaurant).",
                        "path": require("../assets/memories/10.jpg"),
                    },
                    {
                        "title": "Jan. 27 - A rice noodle reunion",
                        "description": "Fried chicken, Fantastic Wok, Drinks at Alan’s, The Last of Us, Burritos, Rice noodles, Basketball cheerleading, The Last of Us Part 2, Kissing goodbye.",
                        "path": require("../assets/memories/11.jpg"),
                    },
                    // {
                    //     "title": "Feb. 3 - INNY's BIRTHDAY",
                    //     "description": "Let's go make some memories babe :D",
                    //     "path": [require("../assets/memories/plain.jpg"), require("../assets/memories/colorized.jpg"),],
                    // },
                ].map(m => <Memory title={m.title} description={m.description} path={m.path}></Memory>)
                }
                <NewMemory title="Feb. 3 - INNY's BIRTHDAY" description="I love you babe, can't wait to take you to Minami and have cocktails with you. 2023 is our year so let's go make some more memories babe 😊!" paths={[require("../assets/memories/plain.jpg"), require("../assets/memories/colorized.jpg")]}></NewMemory>

            </div>
        </div>
    )
}

const Memory = ({ title, description, path,  }) => {
    // Props should contain the Memory title, (description and long) description, image path, and link(s)
    // Should limit description to 1 sentence.
    return (
        <div className='memory'>
            <h2>{title}</h2>
            <p>{description}</p>
            {
                path ?
                <img src={path}></img> : null
            }
        </div>
    )
}

const NewMemory = ({ title, description, paths }) => {
    // Props should contain the Memory title, (description and long) description, image path, and link(s)
    // Should limit description to 1 sentence.
    const [imgIdx, setImgIdx] = useState(0);
    return (
        <div className='' onMouseEnter={e => setImgIdx(1)} onMouseLeave={e => setImgIdx(0)}>
            <h2>{title}</h2>
            <p>{description}</p>
            {
                <img src={paths[imgIdx]}></img>   
            }
        </div>
    )
}

export default InnyBirthday;

import React from 'react'
import './ParticleCatch.css'

const ParticleCatch = () => {
    return (
        <div id='particleCatch'>
            <h1>Particle Catch 🔮</h1>
            <video autoPlay muted loop style={{ maxWidth: '40%', textAlign: 'block' }} src={require('./assets/gameplay.mp4')}></video>
            <div className='article'>
                {/* <img alt='Google Play Feature Image' src={require('./assets/Feature.jpg')}></img> */}
                <p>My latest arcade game in a series of Indie applications I'm developing.
                This was a significant upgrade from my previous iteration of ParticleCatch,
                and I will continue to update this page based on new information (i.e. metrics).</p>
                <h2>Gallery</h2>
                <img style={{ maxWidth: '40%', textAlign: 'block', marginRight: '5%' }} alt='Splash screen' src={require('./assets/ss1.jpg')}></img>
                <img style={{ maxWidth: '40%', textAlign: 'block', marginRight: '5%' }} alt='Gameplay' src={require('./assets/ss2.jpg')}></img>
            </div>
        </div>
    )
}

export default ParticleCatch;